import { defineComponent } from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "RedScreenIndex",
  setup: function setup() {
    var router = useRouter();

    function changeStyle() {
      router.push('/dataScreens');
    }

    return {
      changeStyle: changeStyle
    };
  }
});